<template>
   <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
            <div class="text-center">
                <h4 class="card-title">{{ $t('sideBar.dashboard') }}</h4>
            </div>
        </template>
        <template v-slot:searchBody>
          <h4 class="card-title text-warning" v-if="trainUser">
            <slot  v-if="trainUser.status !== 3">
              {{ $t('externalTraining.dashboard_message') }}
            </slot>
          </h4>
          <h4 class="card-title text-danger" v-else>
            {{ $t('externalTraining.admin_approval') }}
          </h4>
        </template>
      </card>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      overlay: true,
      user: {},
      profileStatus: '',
      approveStatus: '',
      series: [{
        name: 'Total',
        data: [45, 70, 49, 60]
      }],
      search: {
        warehouse_id: '',
        farmer_id: ''
      },
      approvalMessage: false,
      productStockSeriesData: [30, 70],
      deliveryScheduleList: [],
      trainUser: this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee,
      showData: false,
      showHeading: false
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    authUser () {
       return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  mounted () {
  },
  created () {
    if (!this.trainUser) {
      this.$router.push('/training-panel/profile')
    }
    this.checkTrainingUser()
  },
  methods: {
    checkTrainingUser () {
      RestApi.getData(trainingElearningServiceBaseUrl, 'personal-infos/check-user').then(response => {
        if (response.success) {
          this.$store.dispatch('ExternalUserService/getTrainerOrTraineeDetails', response.data)
        } else {
          this.$store.dispatch('ExternalUserService/getTrainerOrTraineeDetails', '')
        }
      })
    }
  }
}
</script>

<style scoped>
.min-height {
  height: 100vh;
}
.my-card {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.t-shadow{
  text-shadow: 1px 1px 1px #000;
}
</style>
